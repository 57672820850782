// Step 1: Import React
import * as React from 'react'
// import Layout from '../components/layout'
import {StaticImage} from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Fb from '../../assets/facebook.svg'
import Gm from '../../assets/google-plus.svg'
import Li from '../../assets/linkedin2.svg'


// Step 2: Define your component
const AboutIntro = () => {
  // console.log(data)
  return (
    
    <section className="about-intro">
        <div className="about-intro__coverimg">
            <StaticImage alt={`cover img`} className="coverimg" src='../../images/windows-lhDjusLtpP4-unsplash.jpg'/>
            <div className="about-intro__bgoverlay"></div>
        </div>
        <div className="about-intro__text">
            <p className="paragraph-sm">Hello Again!</p>
            <p className="heading-lg">Here is a little bit more<span className="pink-text"> about myself</span></p>
            <hr className="lbr" />
            <div className="media">
              <a aria-label="facebook" href='https://www.facebook.com/profile.php?id=100011540748903' className='media__item'><Fb className='icon'/></a>
              <a aria-label="LinkedIn" href='https://www.linkedin.com/in/khanh-linh-le-91b48719a/' className='media__item'><Li className='icon'/></a>
              <a aria-label="Gmail" href='mailto:elearninglilian@gmail.com' className='media__item'><Gm className='icon'/></a>
            </div>
            <div className="btn-group">
                <Link to='/portfolio' state={{category: "default"}} className="btn btn-pink">See My Works</Link>
            </div>
        
        </div>
    </section>
   

   
  )
}
// Step 3: Export your component
export default AboutIntro