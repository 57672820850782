// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

import AboutIntro from '../components/about/about-intro'

// Step 2: Define your component
const AboutPage = () => {
  // console.log(data)
  return (
    <Layout pageTitle="About Page">
        <AboutIntro/>

        <div className="abt__content">

          <div className="abt__content-heading">Background</div>
          <div className="abt__content-heading--sm">I’m a Freelance E-Learning Designer and Developer and an E-learning Researcher from Hanoi, Vietnam.</div>
          <div className="abt__content-list">


            <div className="abt__content-item">
              <div className="abt__content-image--container">
                
                <StaticImage src="../images/portrait.jpg" alt="placeholder" className="abt__content-image" />
                
              </div>
              <div className="abt__content-text">
                <div className="abt__content-text--st">THE ORIGIN</div>
                <div className="abt__content-text--heading">2019 - Working as an educator</div>
                <div className="abt__content-text--paragraph">I started my career in education in 2019 and after knowing about the National competition for E-learning products in Vietnam, I have been interested in the field since then. I started working in Learning and Development in 2020 and I have my first paper on E-learning published in 2021.</div>
              </div>
            </div>
            <div className="abt__content-item">
              <div className="abt__content-text">
                <div className="abt__content-text--st">EXPERIENCE</div>
                <div className="abt__content-text--heading">What I have done so far</div>
                <div className="abt__content-text--paragraph">I’ve worked in various eLearning roles in the public and private sectors, and now I love working as a freelance eLearning Designer and Developer. Working from home allows me to work on multiple projects (national and international) and provide ongoing support for my clients. </div>
              </div>
              <div className="abt__content-image--container">
              
                <StaticImage src="../images/kelly-sikkema-gcHFXsdcmJE-unsplash.jpg" alt="placeholder" className="abt__content-image" />
                
              </div>
              
            </div>
            <div className="abt__content-item">
              <div className="abt__content-image--container">
              
                <StaticImage src="../images/trent-erwin-UgA3Xvi3SkA-unsplash.jpg" alt="placeholder" className="abt__content-image" />                
              </div>
              <div className="abt__content-text">
                <div className="abt__content-text--st">SKILLSET</div>
                <div className="abt__content-text--heading">What I have to offer</div>
                <div className="abt__content-text--paragraph">I enjoy designing for various environments such as face-to-face, online and blended learning. I especially enjoy creating e-learning as I can tap into my artistic skills to incorporate multimedia and storytelling. I like to incorporate my media and artistic skills with my instructional design skills to create engaging and dynamic learning programs and opportunities. </div>
              </div>
            </div>
            


          </div>

        </div>
        {/* <hr className="lbr lbr__abt" /> */}
        <div className="abt__feature">

          <div className="abt__feature-heading--sm">FOR YOUR INFORMATION</div>
          <div className="abt__feature-heading">My Core Values and Principles</div>

          <div className="abt__feature-list">

            <div className="abt__feature-item">
              <div className="abt__feature-item--heading">
                01.
              </div>
              
              <div className="abt__feature-item--paragraph">I'll be completely honest with you of my limitation during the work process</div>
            </div>
            <div className="abt__feature-item">
              <div className="abt__feature-item--heading">
                02.
              </div>
              
              <div className="abt__feature-item--paragraph">I assure you that I will do everything in my power to improve my craft and deliver the best result possible</div>
            </div>
            <div className="abt__feature-item">
              <div className="abt__feature-item--heading">
                03.
              </div>
              
              <div className="abt__feature-item--paragraph">I always put an exceptional amount of thought and passion into every project</div>
            </div>
            <div className="abt__feature-item">
              <div className="abt__feature-item--heading">
                04.
              </div>
              
              <div className="abt__feature-item--paragraph">I love what I do, and I can say that with utmost conviction</div>
            </div>
            
          </div>
        </div>

    </Layout>
   
  )
}
// Step 3: Export your component
export default AboutPage